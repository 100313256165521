<template>
  <div>
    <Divider dashed style="color:#fff">设置概览</Divider>
    <div>
      <h4 class="workplatform-title m-t-10 m-b-20">基础数据</h4>
      <Table
        stripe
        size="small"
        :data="list"
        :columns="tableColumns"
      ></Table>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      tableColumns: [
        { title: '产品类型', key: 'productName' },
        {
          title: '总数/已设置/已提交',
          align: 'right',
          render: (h, params) => {
            return h('span', `${params.row.totalCount} / ${params.row.draftCount} / ${params.row.submitCount}`)
          }
        }
      ]
    }
  },
  computed: {
    list () {
      return this.$store.state.installSetting.orderProductArray
    }
  },
  destroyed () {
    this.$store.commit('set_demand_startDate', '')
    this.$store.commit('set_demand_endDate', '')
  }
}
</script>
